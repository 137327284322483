<template>
	<div class="content-wrapper">

		<div v-if="isList" class="content pt-0">
			<bo-page-title />
			<div class="card">
				<div class="bg-white card-header">
					<div class="row align-items-center">
						<div class="col-md-4 col-lg-5">
							<div class="form-row align-items-center">
								<div class="col-lg-4">
									<h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
								</div>
								<div class="col-lg-8">
									<div class="form-group mb-0">
										<div class="input-group">
											<date-range-picker ref="picker" :locale-data="datePickerConfig.locale"
												:autoApply="datePickerConfig.autoApply" v-model="dateRange" :opens="'right'"
												:ranges="datePickerConfig.ranges" @update="updateValues">
												<template v-slot:input="picker">
													{{ picker.startDate | date }} - {{ picker.endDate | date }}
												</template>
											</date-range-picker>
											<div class="input-group-append calendar-group">
												<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4 col-lg-3">
							<b-form-group class="mb-0">
								<v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.status_ranap"
									:options="Config.mr.statusRanap" label="text" :reduce="v=>v.value" :clearable="true"></v-select>
							</b-form-group>
						</div>
					</div>
				</div>

				<div class="card-header">
					<div class="row">
						<div class="col-md-8 col-lg-9">
							<div class="row gx-1">
								<div class="col-md-auto">
									<div class="form-group mb-0">
										<button @click="doRefreshData" data-toggle="modal" data-target="#FilterField"
											data-popup="tooltip" class="btn btn-light" title="Muat Ulang"
											v-b-tooltip.hover.right.noninteractive><i class="icon-spinner11"></i></button>
									</div>
								</div>
								<div class="col-md">
									<div class="row g-1">

										<div class="col-md-6 col-lg-3">
											<b-form-group class="mb-0">
												<v-select placeholder=" -- Pilih Jaminan -- " @input="doFill" v-model="filter.status_jaminan"
													:options="Config.mr.statusJaminan" label="text" :reduce="v=>v.value"></v-select>
											</b-form-group>
										</div>

										<div class="col-md-6 col-lg-3">
											<b-form-group class="mb-0">
												<v-select placeholder=" -- Pilih Cara Masuk -- " @input="doFill" v-model="filter.cara_masuk"
													:options="Config.mr.caraMasuk" label="text" :reduce="v=>v.value"></v-select>
											</b-form-group>
										</div>


										<div class="col-md-6 col-lg-3">
											<b-form-group class="mb-0">
												<v-select placeholder=" -- Pilih Bangsal -- " @input="doFill" v-model="filter.ranap_bangsal"
													:options="mRanapBangsal" label="text" :reduce="v=>v.value"></v-select>
											</b-form-group>
										</div>

										<div class="col-md-2">
												<v-select placeholder=" -- Pilih Jenis Rawat Inap -- " @input="doFill" v-model="filter.jenis_ranap"  :options="mJenisRanap" :clearable="true" label="text" :reduce="v=>v.value"></v-select>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4 col-lg-3">
							<div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
								<input class="form-control" v-model="filter.search" @input="doSearch"
									placeholder="Ketik Nama/No. RM Pasien" />
								<div class="form-control-feedback">
									<i class="icon-search4 text-indigo"></i>
								</div>

								<b-button class="ml-1 d-inline-flex align-items-center" variant="outline-success" id="resetBtn"
									@click="doResetData()">Reset</b-button>
							</div>
						</div>
					</div>
				</div>



				<div class="table-responsive">
					<table class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
						<thead>
							<tr>
								<th>NO.</th>
								<th class="tbl-sticky-col tsc-left">NO. KEDATANGAN</th>
								<th>TANGGAL MASUK</th>
								<th class="tbl-sticky-col tsc-left tsc-left-last" style="--tsc-pos: 115px;">NAMA/NO KTP</th>
								<th>DOKTER DPJP</th>
								<th>NO RM</th>
								<th>RUANGAN</th>
								<th>JENIS RAWAT INAP</th>
								<th>TANGGAL LAHIR</th>
								<th>USIA</th>
								<th>PPJP</th>
								<th>STATUS</th>
								<th style="min-width: 100px;" class="text-center tbl-sticky-col tsc-right tsc-right-first">AKSI</th>
							</tr>
						</thead>

						<tbody v-if="(dataList||[]).length">
							<template v-for="(v,k) in (dataList||[])">
								<tr :key="k"
									:class="v.aranr_reg_code ? (validationTime(v.aranr_reg_date) && v.aranr_have_kajian_awal_dokter != 'Y' ? 'table-warning':'') : 'table-danger'">
									<td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td class="tbl-sticky-col tsc-left">
										<div class="text-success-700 font-weight-semibold">{{v.aranr_reg_code||"-"}}</div>
									</td>
									<td>
										<div>{{v.aranres_tanggal_periksa | moment("DD MMMM YYYY")}}</div>
									</td>
									<td class="tbl-sticky-col tsc-left tsc-left-last" style="--tsc-pos: 115px;">
										<div>
											<a v-if="v.aranr_reg_code" href="javascript:;" @click="toMonitoring(v.aranr_id)"
												class="font-weight-semibold border-bottom" v-b-tooltip.hover title="Periksa Pasien">
												{{v.ap_fullname||"-"}}
											</a>
											<span v-else>{{v.ap_fullname}}</span>
											
											<br>
											{{v.ap_code||"-"}}</div>
											<small class="text-secondary">Terdaftar {{v.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>

										<div v-if="v.aranr_is_meninggal == 'Y'">
											<template v-if="menuRole('RanapFormulirLainnyaPerawat')">
												<b-badge style="cursor: pointer;" variant="warning" @click="isiFormPenyebabKematian(v.aranr_id)"
													v-b-tooltip.hover.noninteractive="'Click untuk mengisi Form Penyebab Kematian'">MENINGGAL
												</b-badge>
											</template>
											<template v-else>
												<b-badge variant="warning">MENINGGAL</b-badge>
											</template>
										</div>
									</td>
									<td>{{v.bu_full_name||"-"}}</td>
									<td>
										<div class="font-weight-semibold">{{v.ap_code||"-"}}</div>
										<span>dari
											{{v.aranres_cara_masuk == 'RAJAL'?'POLI': v.aranres_cara_masuk == 'BBL' ? 'BBL':'UGD'}}</span>
										<div class="text-success-700 font-weight-semibold">
											{{v.no_reg||"-"}}
										</div>
									</td>
									<td>
										<div class="font-weight-semibold">{{v.mrank_name||"-"}}</div>
										<small><span>{{v.mranb_name||"-"}}</span> - <span>{{v.mrankel_name||"-"}}</span></small>
										<span class="text-secondary" v-if="v.mranbed_name"> - {{v.mranbed_name || "-"}}</span>
									</td>
									<td>
										<div class="font-weight-semibold">
											{{getConfigDynamic(Config.mr.jenisRawatInap,v.aranres_jenis_ranap)||"-"}}</div>
									</td>
									<td>
										<div class="font-weight-semibold" v-if="v.ap_dob">{{v.ap_dob | moment("DD MMMM YYYY") }}</div>
										<span v-else> - </span>
									</td>
									<td>
										<div class="font-weight-semibold">
											{{v.ap_usia_with_ket || "-"}}
										</div>
									</td>
									<td>
										{{v.nama_ppjp||"-"}}
									</td>
									<td>
										
										<div v-if="!v.aranr_reg_code" class="badge badge-danger mb-1">Pasien Belum DiDaftarkan Oleh Admisi</div>

										<div v-if="!v.aranr_ppjp && !v.aranr_perawat_pelaksana" class="badge badge-warning mb-1">Tugaskan
											PPJP</div>
										<div v-else-if="v.aranr_ppjp && !v.aranr_perawat_pelaksana" class="badge badge-warning mb-1">
											Tugaskan Perawat Pelaksana</div>
										<template v-else>
											<div :class="`badge ${v.arans_color} mb-1`">{{v.arans_name||"-"}}</div>

											<template v-if="v.aranr_status == 1">
												<div v-if="v.aranr_is_done_perawat != 'Y'" class="badge badge-warning mb-1">Pengkajian Awal
													Perawat</div>

												<div v-if="v.aranr_is_done_dokter != 'Y'" class="badge badge-warning mb-1">Pengkajian Awal
													Dokter</div>
											</template>

										</template>


									</td>
									<td class="tbl-sticky-col tsc-right tsc-right-first">
										<!--
										<a href="javascript:;" v-if="moduleRole('tandatangani_transfer_intra')"
											class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800"
											v-b-tooltip.hover.right.noninteractive title="Tandatangani Form Transfer Intra">
											<i class="icon-pencil4"></i>
										</a>
										-->

										<a href="javascript:;" v-if="moduleRole('lihat_dokumen_kajian')" @click="doOpenRiwayatKajian(v)"
											class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800"
											data-popup="tooltip" v-b-tooltip.hover.right.noninteractive title="Lihat Dokumen Kajian">
											<i class="icon-file-text2"></i>
										</a>

										<a href="javascript:;" @click.prevent="openModalFormConsent(v.aranr_id)"
											v-if="v.aranr_show_inform_consent_dpjp == 'Y' && moduleRole('form_inform_consent')"
											class="btn btn-icon rounded-round btn-sm alpha-indigo border-indigo text-indigo-800"
											data-popup="tooltip" v-b-tooltip.hover.right.noninteractive
											:title="v.aranr_is_submit_inform_consent_dpjp == 'Y' ? 'Ubah Inform Consent' : 'Isi Inform Consent'">
											<i class="fas fa-notes-medical"></i>
										</a>

										<a href="javascript:;" @click.prevent="openModalFormConsent(v.aranr_id, true)"
											v-if="v.aranr_is_submit_inform_consent_dpjp == 'Y'"
											class="btn btn-icon rounded-round btn-sm border-success text-success" data-popup="tooltip"
											v-b-tooltip.hover.right.noninteractive title="Lihat Form Consent">
											<i class="fas fa-notes-medical"></i>
										</a>

										<a v-if="moduleRole('riwayat_dpjp_pindah_ruang')" href="javascript:;" @click="doOpenRiwayat(v)"
											class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800"
											data-popup="tooltip" v-b-tooltip.hover.right.noninteractive title="Riwayat DPJP Pasien">
											<i class="fa-solid fa-users"></i>
										</a>



										<template>
											<!-- kajian dokter -->
											<template v-if="moduleRole('buat_kajian_medis')">
												<a href="javascript:;" v-if="(v.aranr_dokter_id == user.id || user.levelId == 1) && v.aranr_have_kajian_awal_dokter != 'Y'"
													@click="initKajianAwalDokter(v)"
													class="btn btn-icon rounded-round btn-sm alpha-indigo border-indigo text-indigo-800"
													v-b-tooltip.hover.right.noninteractive title="Tambahkan Kajian Awal Medis">
													<i class="icon-pencil7"></i>
												</a>

												<a href="javascript:;" v-if="(v.aranr_dokter_id == user.id || user.levelId == 1) && v.aranr_have_kajian_awal_dokter == 'Y'"
													@click="lihatKajianDokter(v)"
													class="btn btn-icon rounded-round btn-sm alpha-indigo border-indigo text-indigo-800"
													v-b-tooltip.hover.right.noninteractive
													:title="v.isEdit ? 'Edit Kajian Awal Medis' : 'Tambahkan Kajian Awal Medis'">
													<i :class="v.isEdit ? 'icon-pencil7' : 'icon-pencil7'"></i>
												</a>
											</template>
											<!-- kajian dokter -->

										</template>

										<a href="javascript:;"
											v-if="moduleRole('verifikasi_kajian_persalinan') && v.mranjr_code == 'OB' && v.aranr_is_verify_kajian_persalinan != 'Y'"
											@click="openModalVerifikasiKajianPersalinan(v)"
											class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800"
											v-b-tooltip.hover.right.noninteractive title="Verifikasi Kajian Persalinan">
											<i class="icon-file-check2"></i>
										</a>

										<div v-if="validationTime(v.aranr_reg_date) && v.aranr_have_kajian_awal_dokter != 'Y'"
											v-b-tooltip.hover="'Anda telah melewati batas waktu pengisian Kajian Awal Medis (1x24 jam)'"
											class="badge badge-danger mb-1">Batas Waktu Kajian Awal</div>

									</td>

								</tr>
							</template>
						</tbody>
						<tbody v-if="data.data.length == 0">
							<tr>
								<td colspan="99">
									<div class="text-center">
										<h4 align="center"><span
												v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian
												tidak ditemukan</span>
											<span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
									</div>
								</td>
							</tr>
						</tbody>
						<tbody v-if="!data.data">
							<tr>
								<td colspan="99">
									<div class="skeletal-comp"></div>
								</td>
							</tr>
							<tr>
								<td colspan="99">
									<div class="skeletal-comp"></div>
								</td>
							</tr>
							<tr>
								<td colspan="99">
									<div class="skeletal-comp"></div>
								</td>
							</tr>
						</tbody>
					</table>


					<div
						:class="(dataList||[]).length && !loadingTable && dataList ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
						<a href="javascript:;" data-scroll="left"
							class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
							<i class="icon-chevron-left"></i>
						</a>
						<a href="javascript:;" data-scroll="right"
							class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
							<i class="icon-chevron-right"></i>
						</a>
					</div>
				</div>

				<b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">
					<b-pagination class="mb-0" v-model="pageNo" :per-page="data.per_page" :total-rows="data.total" />
				</b-card-footer>

			</div>

			<validation-observer ref="VFormSubmitPerawatPelaksana">
				<b-modal v-model="openPerawatPelaksana" @ok.prevent="submitPerawatPelaksana" size="lg" no-close-on-backdrop
					class="text-center" :title="'Tugaskan Perawat Pelaksana'">
					<table class="table table-sm table-bordered">
						<thead>
							<tr>
								<th colspan="6">Informasi Pasien</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td colspan="2">
									<div class="result_tab">
										<h4>Nama</h4>
										<p>{{rowEdit.ap_fullname||"-"}}</p>
									</div>
								</td>
								<td colspan="2">
									<div class="result_tab">
										<h4>Tanggal Lahir / Umur</h4>
										<p>{{rowEdit.ap_dob | moment("DD MMMM YYYY") }} / {{rowEdit.ap_usia}} Tahun</p>
									</div>
								</td>
								<td colspan="2">
									<div class="result_tab">
										<h4>Jenis Kelamin</h4>
										<p>{{rowEdit.ap_gender == 1 ? "Laki-Laki":"Perempuan"}}</p>
									</div>
								</td>
							</tr>
							<tr>
								<td colspan="2">
									<div class="result_tab">
										<h4>Ruangan</h4>
										<p>{{rowEdit.mranb_name||"-"}}</p>
									</div>
								</td>
								<td colspan="2">
									<div class="result_tab">
										<h4>Kamar / No. Tempat Tidur / Kelas</h4>
										<p>{{rowEdit.mranbed_name||"-"}} / {{rowEdit.mrankel_name||"-"}}</p>
									</div>
								</td>
								<td colspan="2">
									<div class="result_tab">
										<h4>Kelas Titipan</h4>
										<p>-</p>
									</div>
								</td>
							</tr>
							<tr>
								<td colspan="2">
									<div class="result_tab">
										<h4>Jenis Cara Bayar</h4>
										<p>{{getConfigDynamic(Config.mr.statusJaminan,rowEdit.aranres_jaminan)||"-"}}</p>
									</div>
								</td>
								<td colspan="2">
									<div class="result_tab">
										<h4>Alergi</h4>
										<ul v-if="(rowEdit.alergi||[]).length" class="pl-3">
											<li v-for="(v,k) in (rowEdit.alergi||[])" :key="k+'alergi'">
												{{v.name||"-"}} - <span class="font-weight-semibold">{{v.jenis||"-"}}</span>
											</li>
										</ul>
										<span v-else>Tidak ada Alergi</span>
									</div>
								</td>
								<td colspan="2">
									<div class="result_tab">
										<h4>Diagnosa Masuk</h4>
										<p>{{rowEdit.diagnosa||"-"}}</p>
									</div>
								</td>
							</tr>
						</tbody>

						<thead>
							<tr>
								<th colspan="6">Penugasan Perawat Pelaksana</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td colspan="3" width="50%">
									<div>
										<label for="selPppjp">Pilih Perawat Penanggung jawab Pasien (PPJP)</label>
										<v-select placeholder=" -- Pilih Perawat Penanggung jawab Pasien -- " 
										v-model="rowEdit.aranr_ppjp" :clearable="true" :options="mPPJP" label="text" :reduce="v=>v.value">
												<template slot="selected-option" slot-scope="option">
												<span :title="option.text+' - menangani '+option.pasien + ' Pasien'" v-b-tooltip.hover.right>{{ option.text }}</span>
												</template>
												<template slot="option" slot-scope="option">
												<span :title="option.text+' - menangani '+option.pasien + ' Pasien'" v-b-tooltip.hover.right>{{ option.text }} - menangani 
														<span class="font-weight-semibold"> {{option.pasien}} Pasien </span></span>
												</template>
										</v-select>
									</div>
									<VValidate :name="'Perawat Penanggung Jawab Pasien'" v-model="rowEdit.aranr_perawat_pelaksana"
										:rules="{required : 1}" />
								</td>
								<td colspan="3" width="50%">
									<div></div>
								</td>
							</tr>
						</tbody>
					</table>
				</b-modal>
			</validation-observer>

		</div>

		<b-modal size="xl" id="modal_inform_consent" no-close-on-backdrop hide-footer title="Form Inform Consent">
			<validation-observer ref="VFormConsent" v-slot="{handleSubmit}">
				<b-overlay :show="loadingConsent">
					<b-form @submit.prevent="handleSubmit(doSubmitConsent)">
						<b-tabs v-model="tabIndex" nav-class="nav-tabs-bottom nav-justified mt-2 mb-2">
							<b-tab v-for="(value, index) in dataModal.forms" :key="index" :title="value.aranic_name"
								:active="index == 0">
								<table class="table table-bordered table-sm table-input">
									<thead>
										<tr>
											<td colspan="3">
												<div class="result_tab">
													<h4>Nama Tindakan</h4>
													<p>{{dataModal.forms[index].aranic_name}}</p>
												</div>
											</td>
										</tr>
										<tr>
											<th width="24">No.</th>
											<th>Jenis Informasi</th>
											<th>Isi Informasi</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>1</td>
											<td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
											<td class="input-cell">
												<textarea v-model="dataModal.forms[index].aranic_diagnosis" @input="autoSave" rows="2"
													placeholder="Informasi Terkait" class="form-control"></textarea>
											</td>
										</tr>
										<tr>
											<td>2</td>
											<td>Dasar Diagnosis</td>
											<td class="input-cell">
												<textarea v-model="dataModal.forms[index].aranic_dasar_diagnosis" @input="autoSave" rows="2"
													placeholder="Informasi Terkait" class="form-control"></textarea>
											</td>
										</tr>
										<tr>
											<td>3</td>
											<td>Tindakan Kedokteran</td>
											<td class="input-cell">
												<textarea v-model="dataModal.forms[index].aranic_tindakan_dokter" @input="autoSave" rows="2"
													placeholder="Informasi Terkait" class="form-control"></textarea>
											</td>
										</tr>
										<tr>
											<td>4</td>
											<td>Indikasi Tindakan</td>
											<td class="input-cell">
												<textarea v-model="dataModal.forms[index].aranic_indikasi_tindakan" @input="autoSave" rows="2"
													placeholder="Informasi Terkait" class="form-control"></textarea>
											</td>
										</tr>
										<tr>
											<td>5</td>
											<td>Tata Cara</td>
											<td class="input-cell">
												<textarea v-model="dataModal.forms[index].aranic_tata_cara" @input="autoSave" rows="2"
													placeholder="Informasi Terkait" class="form-control"></textarea>
											</td>
										</tr>
										<tr>
											<td>6</td>
											<td>Tujuan</td>
											<td class="input-cell">
												<textarea v-model="dataModal.forms[index].aranic_tujuan" @input="autoSave" rows="2"
													placeholder="Informasi Terkait" class="form-control"></textarea>
											</td>
										</tr>
										<tr>
											<td>7</td>
											<td>Risiko</td>
											<td class="input-cell">
												<textarea v-model="dataModal.forms[index].aranic_risiko" @input="autoSave" rows="2"
													placeholder="Informasi Terkait" class="form-control"></textarea>
											</td>
										</tr>
										<tr>
											<td>8</td>
											<td>Komplikasi</td>
											<td class="input-cell">
												<textarea v-model="dataModal.forms[index].aranic_kompilasi" @input="autoSave" rows="2"
													placeholder="Informasi Terkait" class="form-control"></textarea>
											</td>
										</tr>
										<tr>
											<td>9</td>
											<td>Prognosis</td>
											<td class="input-cell">
												<textarea v-model="dataModal.forms[index].aranic_prognosis" @input="autoSave" rows="2"
													placeholder="Informasi Terkait" class="form-control"></textarea>
											</td>
										</tr>
										<tr>
											<td>10</td>
											<td>Alternatif & Risiko</td>
											<td class="input-cell">
												<textarea v-model="dataModal.forms[index].aranic_alternatif" @input="autoSave" rows="2"
													placeholder="Informasi Terkait" class="form-control"></textarea>
											</td>
										</tr>
										<tr>
											<td>11</td>
											<td>Lain-lain</td>
											<td class="input-cell">
												<textarea v-model="dataModal.forms[index].aranic_lainnya" @input="autoSave" rows="2"
													placeholder="Informasi Terkait" class="form-control"></textarea>
											</td>
										</tr>
									</tbody>
									<tfoot>
										<tr>
											<td width="50%" colspan="2">
												<div>
													<label for="">Apakah pasien/pendamping pasien menyetujui tindakan? <strong
															class="text-danger">*</strong></label>
													<div>
														<b-form-radio-group :options="Config.mr.pasienSetuju"
															v-model="dataModal.forms[index].aranic_is_pasien_setuju" @input="autoSave" />
														<VValidate :vid="'setuju_pasien' + index" :name="`Persetujuan Pasien`"
															v-model="dataModal.forms[index].aranic_is_pasien_setuju" :rules="{required:1}" />
													</div>
												</div>
											</td>
											<td width="50%">
												<div>
													<label for="">Persetujuan tindakan diberikan kepada <strong
															class="text-danger">*</strong></label>
													<div>
														<b-form-radio-group :options="Config.mr.persetujuanTindakan"
															v-model="dataModal.forms[index].aranic_persetujuan_tindakan" @input="autoSave" />
														<VValidate :vid="'setuju_diberikan' + index" :name="`Persetujuan Tindakan Kepada`"
															v-model="dataModal.forms[index].aranic_persetujuan_tindakan" :rules="{required:1}" />
													</div>
												</div>
											</td>
										</tr>
									</tfoot>
								</table>
							</b-tab>
						</b-tabs>
						<div class="text-right mt-3">
							<button type="button" class="btn" @click="closeModalInformCosent">Batal</button>
							<button type="button" @click="doSubmitConsent" class="btn btn-primary">Submit <i
									class="icon-paperplane ml-2"></i></button>
						</div>
					</b-form>
				</b-overlay>
			</validation-observer>
		</b-modal>

		<b-modal size="xl" id="modal_inform_consent_detail" title="Detail Inform Consent">
			<b-overlay :show="loadingConsent">
				<b-tabs v-model="tabIndex" nav-class="nav-tabs-bottom nav-justified mt-2 mb-2">
					<b-tab v-for="(value, index) in dataModal.forms" :key="index" :title="value.aranic_name" :active="index == 0">
						<table class="table table-bordered table-sm table-input">
							<thead>
								<tr>
									<td colspan="3">
										<div class="result_tab">
											<h4>Nama Tindakan</h4>
											<p>{{dataModal.forms[index].aranic_name}}</p>
										</div>
									</td>
								</tr>
								<tr>
									<th width="24">No.</th>
									<th>Jenis Informasi</th>
									<th>Isi Informasi</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>1</td>
									<td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
									<td>
										{{ dataModal.forms[index].aranic_diagnosis }}
									</td>
								</tr>
								<tr>
									<td>2</td>
									<td>Dasar Diagnosis</td>
									<td>
										{{ dataModal.forms[index].aranic_dasar_diagnosis }}
									</td>
								</tr>
								<tr>
									<td>3</td>
									<td>Tindakan Kedokteran</td>
									<td>
										{{ dataModal.forms[index].aranic_tindakan_dokter }}
									</td>
								</tr>
								<tr>
									<td>4</td>
									<td>Indikasi Tindakan</td>
									<td>
										{{ dataModal.forms[index].aranic_indikasi_tindakan }}
									</td>
								</tr>
								<tr>
									<td>5</td>
									<td>Tata Cara</td>
									<td>
										{{ dataModal.forms[index].aranic_tata_cara }}
									</td>
								</tr>
								<tr>
									<td>6</td>
									<td>Tujuan</td>
									<td>
										{{ dataModal.forms[index].aranic_tujuan }}
									</td>
								</tr>
								<tr>
									<td>7</td>
									<td>Risiko</td>
									<td>
										{{ dataModal.forms[index].aranic_risiko }}
									</td>
								</tr>
								<tr>
									<td>8</td>
									<td>Komplikasi</td>
									<td>
										{{ dataModal.forms[index].aranic_kompilasi }}
									</td>
								</tr>
								<tr>
									<td>9</td>
									<td>Prognosis</td>
									<td>
										{{ dataModal.forms[index].aranic_prognosis }}
									</td>
								</tr>
								<tr>
									<td>10</td>
									<td>Alternatif & Risiko</td>
									<td>
										{{ dataModal.forms[index].aranic_alternatif }}
									</td>
								</tr>
								<tr>
									<td>11</td>
									<td>Lain-lain</td>
									<td>
										{{ dataModal.forms[index].aranic_lainnya }}
									</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td width="50%" colspan="2">
										<div>
											<label for="">Apakah pasien/pendamping pasien menyetujui tindakan?</label>
											<div>
												<strong>{{ dataModal.forms[index].aranic_is_pasien_setuju == 'Y' ? 'Tindakan Disetujui' : 'Tindakan Ditolak' }}</strong>
											</div>
										</div>
									</td>
									<td width="50%">
										<div>
											<label for="">Persetujuan tindakan diberikan kepada</label>
											<div>
												<strong>{{ dataModal.forms[index].aranic_persetujuan_tindakan == 'P' ? 'Pasien' : 'Penanggungjawab Pasien' }}</strong>
											</div>
										</div>
									</td>
								</tr>
							</tfoot>
						</table>
					</b-tab>
				</b-tabs>
			</b-overlay>
		</b-modal>

		<b-modal id="verifikasiKajianPersalinan" size="lg" centered title="Verifikasi Kajian Persalinan" hide-footer>
			<b-tabs nav-class="nav-tabs-bottom nav-justified mb-0" v-if="showTabKajianPersalinan">
				<b-tab title="Kajian Persalinan">
					<b-tabs nav-class="nav-tabs-bottom nav-justified mb-0" v-if="showTabKajianPersalinan">
						<b-tab title="Kajian Mandiri SC" v-if="!emptyKajianMandiri">
							<KajianMandiri :data="kajianPersalinanData.kajianMandiri" />
						</b-tab>
						<b-tab title="Catatan Persalinan" v-if="!emptyCatatanPersalinan">
							<CatatanPersalinan :data="kajianPersalinanData.catatanPersalinan" />
						</b-tab>
						<b-tab title="Laporan Persalinan" v-if="!emptyLaporanPersalinan">
							<LaporanPersalinan :data="kajianPersalinanData.laporanPersalinan" />
						</b-tab>
					</b-tabs>
				</b-tab>
			</b-tabs>

			<validation-observer ref="VFormVerifikasiKajianPersalinan">
				<b-form @submit.prevent="doVerifyKajianPersalinan()">
					<label class="font-weight-bold mt-3">
						Verifikasi Kajian Persalinan
					</label>
					<b-form-group>
						<b-form-checkbox-group v-model="dataModal.verify" name="flavour-2">
							<template v-if="kajianPersalinanData.isVerifKajianMandiriSC == 'Y'">
								<i class="icon-checkmark2 text-success"></i> Kajian Mandiri SC
							</template>
							<b-form-checkbox value="KAJIAN_MANDIRI_SC" v-else>
								Kajian Mandiri SC
							</b-form-checkbox>

							<template v-if="kajianPersalinanData.isVerifCatatanPersalinan == 'Y'">
								<i class="icon-checkmark2 text-success"></i> Catatan Persalinan
							</template>
							<b-form-checkbox value="CATATAN_PERSALINAN" v-else>Catatan Persalinan</b-form-checkbox>

							<template v-if="kajianPersalinanData.isVerifLaporanPersalinan == 'Y'">
								<i class="icon-checkmark2 text-success"></i> Laporan Persalinan
							</template>
							<b-form-checkbox value="LAPORAN_PERSALINAN" v-else>Laporan Persalinan</b-form-checkbox>
						</b-form-checkbox-group>
					</b-form-group>

					<div class="text-right">
						<button class="btn btn-labeled btn-labeled-left btn-primary" type="submit">
							<span>Submit</span>
							<b><i class="icon-paperplane"></i></b>
						</button>
					</div>
				</b-form>
			</validation-observer>


		</b-modal>

		<b-modal size="lg" v-model="openRiwayat" title="Riwayat DPJP & Pindah Ruangan Pasien" ok-only ok-title="Tutup">
			<b-tabs nav-class="nav-tabs-bottom nav-justified mb-0">
				<b-tab title="Riwayat DPJP">
					<div class="row mt-2">
						<div class="col-md-12 mb-2">
							<table class="table table-sm table-bordered">
								<thead>
									<tr>
										<th>Dari Dokter</th>
										<th>Ke Dokter</th>
										<th>Tanggal Pindah</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>-</td>
										<td>{{rowEdit.bu_full_name||"-"}}</td>
										<td>{{rowEdit.aranr_created_date | moment("DD MMMM YYYY, HH:mm")}}</td>
									</tr>
									<tr v-for="(v,k) in (logDPJP||[])" :key="k">
										<td>{{v.dpjp_from||"-"}}</td>
										<td>{{v.dpjp_to||"-"}}</td>
										<td>{{v.aranlpd_date | moment("DD MMMM YYYY, HH:mm")}}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</b-tab>
				<b-tab title="Riwayat Pindah Ruang">
					<div class="row mt-2">
						<div class="col-md-12 mb-2">
							<table class="table table-sm table-bordered">
								<thead>
									<tr>
										<th>Jenis Rawat Inap</th>
										<th>Bangsal Asal</th>
										<th>Bangsal Tujuan</th>
										<th>Tanggal Pindah</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(v,k) in (logPindahRuang||[])" :key="k">
										<td>{{v.mranjr_name||"-"}}</td>
										<td>{{getConfigDynamic(mRanapBangsal,v.aranpr_bangsal_asal)||"-"}}</td>
										<td>{{getConfigDynamic(mRanapBangsal,v.aranpr_bangsal)||"-"}}</td>
										<td>{{v.aranpr_created_date | moment("DD MMMM YYYY, HH:mm")}}</td>
									</tr>
									<tr v-if="!(logPindahRuang||[]).length">
										<td class="text-center" colspan="99">Tidak ada data</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</b-tab>
			</b-tabs>
		</b-modal>


		<b-modal v-model="isOpenModalHistory" :title="'Riwayat Rekam Medis'" size="xl" ok-only ok-title="Tutup">
			<b-tabs nav-class="nav-tabs-bottom nav-justified mt-2 mb-0">
				<b-tab title="UGD">
					<HistoryUGD :dataProps="dataUGD" :pasienId="rowEdit.aranr_ap_id" />
				</b-tab>
				<b-tab title="Rawat Jalan">
					<HistoryRawatJalan :dataProps="dataPoli" :pasienId="rowEdit.aranr_ap_id" />
				</b-tab>
			</b-tabs>
		</b-modal>
	</div>
</template>

<script>
    import GlobalVue from '@/libs/Global.vue'
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

    import KajianMandiri from '@/components/Ranap/KajianPersalinanCmp/KajianMandiri.vue'
    import LaporanPersalinan from '@/components/Ranap/KajianPersalinanCmp/LaporanPersalinan.vue'
    import CatatanPersalinan from '@/components/Ranap/KajianPersalinanCmp/CatatanPersalinan.vue'
    import HistoryUGD from '@/components/Ranap/HistoryUGD.vue'
    import HistoryRawatJalan from '@/components/Ranap/HistoryRawatJalan.vue'

    const moment = require('moment')
    
    // import Datepicker from 'vuejs-datepicker'


    const _ = global._
    import $ from 'jquery'
    import Gen from '@/libs/Gen.js'

    export default {
        extends: GlobalVue,
  	    components:{
            DateRangePicker,
            KajianMandiri,
            LaporanPersalinan,
            CatatanPersalinan,
            HistoryUGD,
            HistoryRawatJalan
        },
        computed:{
            isDetail(){
                return this.$route.query.isDetail
            },
            isDoc(){
                return this.$route.query.isDoc
            },
            passToSub(){
                return {
                    Config:this.Config,
                    mrValidation: this.mrValidation,
                    rowPasien: this.rowPasien,
                    isShowBangsal: this.isShowBangsal,
                    infoBangsal: this.infoBangsal,
                    katalogBangsal: this.katalogBangsal
                }
            },
            showTabKajianPersalinan() {
                return !this.emptyKajianMandiri || !this.emptyLaporanNifas || !this.emptyCatatanPersalinan || !this.emptyLaporanPersalinan
            },
            emptyKajianMandiri() {
                return !Object.keys(this.kajianPersalinanData.kajianMandiri || {}).length
            },
            emptyCatatanPersalinan() {
                return !Object.keys(this.kajianPersalinanData.catatanPersalinan || {}).length
            },
            emptyLaporanPersalinan() {
                return !(this.kajianPersalinanData.laporanPersalinan || []).length
            },
        },
        data(){
            return {
                idKey:'aranres_id',
                datePickerConfig: {
                    startDate: new Date(moment().subtract(6, 'days')),
                    endDate: new Date(),
                    autoApply: true,
                    ranges: {
                        'Hari Ini': [new Date(), new Date()],
                        '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                        '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                    },
                    applyClass: 'btn-sm btn-primary',
                    cancelClass: 'btn-sm btn-light',
                    locale: {
                        applyLabel: 'Terapkan',
                        cancelLabel: 'Batal',
                        direction: 'ltr',
                        format: 'mm/dd/yyyy',
                        separator: ' - ',
                    }
                },
                dateRange: {
                    startDate: new Date(moment().subtract(6, 'days')),
                    endDate: new Date(),
                },

                rowEdit : {},
                rowPasien : {},
                rowAdditional: {},
                mRanapBangsal : [],
								mJenisRanap: [],
                kajianPersalinanData: {},
                mPPJP: [],
                openPerawatPelaksana: false,
                loadingConsent: false,
                dataModal: {},
                tabIndex: 0,

                openRiwayat: false,
                logDPJP: [],
                logPindahRuang: [],

                isOpenModalHistory: false,
                dataPoli: {},
                dataUGD: {}
            }
        },
        mounted(){
            this.filter.status_jaminan = 'ALL'
            this.filter.cara_masuk = 'ALL'
            this.filter.ranap_bangsal = this.user.bu_bangsal_id
            this.filter.status_ranap = 2
            
            this.doConvertDate()      
            this.apiGet()
            
            // if(this.isList){
            //     this.apiGetResume()
            // }
                
            setTimeout(()=>{
                this.setSlide()
            },1000)
        },
        methods: {
            isiFormPenyebabKematian(aranr_id){
                this.loadingOverlay = true

                Gen.apiRest("/get/RanapPasienPerawatan/get-formulir-lainnya-id", {
                    params: { aranr_id: aranr_id, slug: 'surat-keterangan-penyebab-kematian' }
                }).then(res => {
                    this.$router.push({name: 'RanapFormulirLainnyaPerawat', params: {pageSlug: aranr_id, type: res.data.slug, id: res.data.id}})
                    this.loadingOverlay = false
                }).catch(err => {
                    this.loadingOverlay = false
                    this.$swal({
                    icon: 'error',
                    text: err.response.data.message || "Terjadi suatu kesalahan!"
                    })
                })
            },
            doOpenRiwayatKajian(row){
                this.rowEdit = row
                this.loadingOverlay = true
                Gen.apiRest(
                    "/do/" + 'RanapPasienPerawatan', {
                    data: {
                        type: "get-riwayat-poli",
                        id: this.rowEdit.aranr_ap_id
                    }
                    },
                    "POST"
                ).then(res => {
                    let resp = res.data
                    this.dataPoli = resp.dataPoli
                })

                Gen.apiRest(
                    "/do/" + 'RanapPasienPerawatan', {
                    data: {
                        type: "get-riwayat-ugd",
                        id: this.rowEdit.aranr_ap_id
                    }
                    },
                    "POST"
                ).then(res => {
                    let resp = res.data
                    this.loadingOverlay = false
                    this.isOpenModalHistory = true
                    this.dataUGD = resp.dataUGD
                })
            },
            doOpenRiwayat(row) {
                this.rowEdit = row
                this.loadingOverlay = true
                let data = row
                data.type = "open-riwayat"
                Gen.apiRest(
                    "/do/" + 'RanapPasienPerawatan', {
                    data: data
                    },
                    "POST"
                ).then(res => {
                    let resp = res.data
                    this.loadingOverlay = false
                    this.openRiwayat = true

                    this.logDPJP = resp.logDPJP
                    this.logPindahRuang = resp.logPindahRuang
                })
            },
            getKajianPersalinanData(id){
                Gen.apiRest(
                    "/do/" + 'RanapPasienPerawatan', {
                    data: {
                        type: "get-detail-kajian-persalinan",
                        regId: id
                    }
                    },
                    "POST"
                ).then(res => {
                    let resp = res.data
                    if(!resp.isFound){
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Kajian persalinan Tidak Ditemukan'
                    })
                    }
                    this.kajianPersalinanData = resp.data
                })
            },
            doVerifyKajianPersalinan(){
                this.$refs['VFormVerifikasiKajianPersalinan'].validate().then(success => {
                    if(!success) return false

                    this.loadingOverlay = true
                    Gen.apiRest(
                        "/do/" + 'RanapPasienPerawatan', {
                            data: { 
                                ...this.dataModal, 
                                type: 'verifikasi-kajian-persalinan',
                                isVerifKajianMandiriSC: this.kajianPersalinanData.isVerifKajianMandiriSC,
                                isVerifLaporanPersalinan: this.kajianPersalinanData.isVerifLaporanPersalinan,
                                isVerifCatatanPersalinan: this.kajianPersalinanData.isVerifCatatanPersalinan,
                            }
                        },
                        "POST"
                    ).then(() => {
                        this.loadingOverlay = false

                        this.$swal({
                            icon: 'success',
                            text: 'Berhasil Verifikasi Kajian Persalinan'
                        }).then(() => {
                            this.$bvModal.hide('verifikasiKajianPersalinan')
                            this.apiGet()
                        })
                    }).catch(() => {
                        this.loadingOverlay = false
                        this.$swal({
                            icon: 'error',
                            text: 'Terjadi suatu kesalahan saat verifikasi kajian persalinan'
                        })
                    })
                })
            },
            openModalVerifikasiKajianPersalinan(data) {
                this.$bvModal.show('verifikasiKajianPersalinan')
                this.$set(this, 'dataModal', data)

                this.getKajianPersalinanData(data.aranr_id)
            },
            hitSaveDraft(){
                return Gen.apiRest("/do/" + this.modulePage, {
                    data: { type: 'save-draft-consent', payload: this.dataModal }
                })
            },
            autoSave: _.debounce(function(){
                this.hitSaveDraft()
            }, 1000),
            openModalFormConsent(ar_id, is_detail = false){
                this.$set(this.dataModal, 'forms', [])
                if(is_detail){
                    this.$bvModal.show('modal_inform_consent_detail')
                }else{
                    this.$bvModal.show('modal_inform_consent')
                }

                this.loadingConsent = true

                Gen.apiRest("/get/" + this.modulePage + "/get-inform-consent-bbl", {
                    params: {ar_id: ar_id}
                }).then(res => {
                    this.loadingConsent = false
                    this.$set(this.dataModal, 'forms', res.data.forms)
                    this.$set(this.dataModal, 'ar_id', ar_id)
                }).catch(err => {
                    console.log(err)
                    this.loadingConsent = false
                    this.$swal({
                        icon: 'error',
                        text: 'Terjadi suatu kesalahan saat pengambilan data'
                    }).then(() => {
                        if(is_detail){
                            this.$bvModal.hide('modal_inform_consent_detail')
                        }else{
                            this.$bvModal.hide('modal_inform_consent')
                        }
                    })
                })
            },
            closeModalInformCosent(){
                this.tabIndex = 0
                this.$set(this, 'dataModal', {})
                this.$bvModal.hide('modal_inform_consent')
            },
            doSubmitConsent(){
                for(let i = 0; i < (this.dataModal.forms || []).length; i++){
                    if(!this.dataModal.forms[i].aranic_is_pasien_setuju || !this.dataModal.forms[i].aranic_persetujuan_tindakan){
                        this.tabIndex = i
                        return
                    }
                }

                this.$refs.VFormConsent.validate().then(success => {
                    if(!success) return

                    this.loadingOverlay = true

                    Gen.apiRest("/do/" + this.modulePage, {
                        data: { type: 'save-consent', payload: this.dataModal }
                    }).then(res => {
                        this.loadingOverlay = false
                        this.$swal({
                            icon: 'success',
                            title: res.data.message
                        }).then(() => {
                            this.closeModalInformCosent()
                            this.apiGet()
                        })
                    }).catch(err => {
                        this.loadingOverlay = false
                        this.$swal({
                            icon: 'error',
                            text: err.response.data.message || 'Terjadi suatu kesalahan!'
                        })
                    })
                })
            },
            toMonitoring(regID){
                this.$router.push({ name: 'RanapMonitoring', params: {pageSlug: regID}}).catch(()=>{})
            },
            submitPerawatPelaksana(){
                this.$refs['VFormSubmitPerawatPelaksana'].validate().then(success=>{
                    if(!success){
                        return this.$swal({
                            icon: 'error',
                            title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                        })
                    }else{
                        this.loadingOverlay = true
                        let data = this.rowEdit
                        data.type = 'assign-perawat-pelaksana'
                       
                        Gen.apiRest(
                            "/do/"+this.modulePage,
                            {data: data}, 
                            "POST"
                        ).then(res=>{
                            let resp = res.data
                            this.loadingOverlay = false
                            this.$swal({
                                title: resp.message,
                                icon: "success",
                                confirmButtonText: 'Ok',
                                allowOutsideClick: false,
                                allowEscapeKey: false
                            }).then(result => {
                                if(result.value){
                                    this.openPerawatPelaksana = false
                                    this.apiGet()
                                }
                            })
                        })
                    }
                })
            },
            tugasKanPPJP(v){
                this.rowEdit = v
                let data = this.rowEdit
                data.type = 'get-data-alergi-diagnosa'
                this.loadingOverlay = true                 

                Gen.apiRest(
                    "/do/"+this.modulePage,
                    {data: data}, 
                    "POST"
                ).then(res=>{
                    let resp = res.data
                    this.openPerawatPelaksana = true
                    this.loadingOverlay = false     
                    this.rowEdit.alergi = resp.alergi
                    this.rowEdit.diagnosa = resp.diagnosa     

                })

            },
            setSlide(){
                const responsiveTable = document.querySelector('.table-responsive')
                if(responsiveTable){
                    const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                    const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
                    if(responsiveTableScroll){
                        // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                        Array.from(responsiveTableScrollActions).forEach(action => {
                            action.addEventListener('click', e => {
                                const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                                 
                                responsiveTable.scroll({
                                    left: scrollAmount,
                                    behavior: 'smooth'
                                })
                            })
                        })
                    }
                }
            },
            doResetData(){
                this.doReset()
                this.dateRange =  {
                    startDate: new Date(moment().subtract(6, 'days')),
                    endDate: new Date(),
                }
                this.doConvertDate()
            },
            doRefreshData(){
                this.apiGet()
                window.scrollTo(0,0)
            },
            apiGetResume(){
                let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
                Gen.apiRest(
                    "/get/"+this.modulePage+'/resume', 
                    {
                    params: Object.assign({}, paramsQuery||{})
                    }
                ).then(res=>{
                    _.forEach(res.data, (v,k)=>{
                        this.$set(this, k, v)
                    })
                })
            },
            doConvertDate(){
                this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
                this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
            },
            doPageOne(){
                this.filter.page = 1
            },
            doFill(){
                this.doPageOne()
                this.doFilter()
            },
            updateValues(e){
                this.doConvertDate()
                this.doFill()
            },
            doSearch: _.debounce(function(){
                this.doPageOne()
                this.doFilter()
            },500),
            getConfigDynamic(master,value){
                let text = ''
                if(value){
                    let index = (master||[]).findIndex(x => x.value == value)
                    if(index !== -1){
                        text = master[index]['text']
                    }
                }
                return text
            },
            to24Hours(date){
                return moment(date).add(24, 'hours').format('YYYY-MM-DD HH:mm')
            },
            validationTime(regDate){
                console.log(this.to24Hours(regDate))
                if(moment().format('YYYY-MM-DD HH:mm') >= this.to24Hours(regDate)){
                    return true
                }else{
                    return false
                }
            },
            initKajianAwalDokter(row){
                this.createKajianAwal(row)                    
            },
            createKajianAwal(row){
                this.loadingOverlay = true
                let data = {
                    idPasien : row.aranr_ap_id,
                    regId : row.aranr_id,
                    dokterId : row.aranr_dokter_id,
                    dokterRaber : row.aranr_dokter_raber_id,
                    jenisRawatInap : row.aranr_jenis_ranap
                }
                data.type = 'init-kajian-awal-dokter'
                Gen.apiRest(
                    "/do/"+this.modulePage,
                    {data: data}, 
                    "POST"
                ).then(res=>{
                    let resp = res.data
                    this.loadingOverlay = false
                    this.$router.push({ name: resp.menuKajian, params: {pageSlug: row.ap_id, rmNo: resp.aranrm_id}, query: {regId: row.aranr_id} }).catch(()=>{})
                })  
            },

            lihatKajianDokter(row){
                this.$router.push({ name: this.toMenuKajianAwalDokter(row.aranr_kajian_awal_type), params: {pageSlug: row.ap_id, rmNo: row.kajian_dokter_id}, query: {regId: row.aranr_id} }).catch(()=>{})
            }
        },
        filters: {
            date(val) {
                return val ? moment(val).format("D MMM YYYY") : ""
            }
        },
        watch:{
            $route(){
                this.apiGet()
                
                // if(this.isList){
                //     this.apiGetResume()
                // }
                
                setTimeout(()=>{
                    if(this.isList){
                        this.setSlide()
                    }
                },1000)
            },
            '$route.params.pageSlug':function(){
                this.$set(this,'filter',{})
            },
            'openPerawatPelaksana':function(v){
                if(!v){
                    this.apiGet()
                }
            }
        }
    }
</script>